import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import bg from 'hsi/themes/hsi/img/hs-alert-invite-bg.svg';
import IconRouter from 'hsi/components/IconRouter';
import useConfig from 'hsi/hooks/useConfig';

import tns from 'hsi/i18n/tns';
const t = tns('alertInvite');

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#EAEFF5',
    },
    bg: {
        backgroundImage: `url('${bg}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom',
        width: '100vw',
        height: '100vh',
        maxHeight: '400px',
        position: 'absolute',
        bottom: '0px',
    },
    content: {
        marginTop: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        width: '38px',
        height: '54px',
        marginBottom: 10,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 20,
    },
    actionLink: {
        textDecoration: 'none',
        fontWeight: 'bold',
    },
    error: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    resErr: {
        fontWeight: 'normal',
    },
});

const HandleAlertInvite = ({success, error}) => {
    const config = useConfig();
    const cl = useStyles();

    const resText = success ? (
        t('success')
    ) : (
        <div className={cl.error}>
            <div>{t('error1')}</div>
            <div>{t('error2')}</div>
            <div className={cl.resErr}>{error && `(${JSON.stringify(error)})`}</div>
        </div>
    );

    return (
        <div className={cl.container}>
            <div className={cl.bg} />
            <div className={cl.content}>
                <IconRouter name="idea" className={cl.icon} />
                <div className={cl.title}>{resText}</div>
                <div>{t('actionDesc')}</div>
                <a
                    className={cl.actionLink}
                    href={config.links.alertInviteAction}
                    rel="noreferrer"
                    target="_blank"
                >
                    {t('actionLink')}
                </a>
            </div>
        </div>
    );
};

export default HandleAlertInvite;
