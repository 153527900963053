import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

//Components
import IconRouter from 'hsi/components/IconRouter';
import PulseLoader from 'hsi/components/PulseLoader';

//Actions
import {reset as resetFilters} from 'hsi/actions/filtersActions';

//Other
import {selectLinkedinChannelIds} from 'hsi/selectors';
import {T} from 'hsi/i18n';
import useStyles from './styles';

const LinkedinWarning = ({classes}) => {
    const dispatch = useDispatch();
    return (
        <div className={classes.subtitle}>
            <span>{T('noSearchResults.linkedinWarning')}</span>
            <button className={classes.link} onClick={() => dispatch(resetFilters({reset: true}))}>
                {T('noSearchResults.clearFilters')}
            </button>
        </div>
    );
};

//The component
function NoResultsMsg({isSavedSearch, noResults, percentComplete, isLinkedinSearch}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {noFiltersApplied, filters} = useSelector((state) => state.filters);

    const linkedinChannelIds = useSelector(selectLinkedinChannelIds);
    const hasLinkedinFilter = !!filters?.pageType?.linkedin;
    const showLinkedinWarning = hasLinkedinFilter && isEmpty(linkedinChannelIds);

    const quickSearchLoading = useMemo(
        () => !isSavedSearch && !noResults,
        [isSavedSearch, noResults],
    );
    const savedSearchLoading = useMemo(
        () => isSavedSearch && ((percentComplete !== 100 && !isLinkedinSearch) || !noResults),
        [isSavedSearch, noResults, percentComplete, isLinkedinSearch],
    );

    return (
        <div className={classes.root} role="status" aria-live="polite">
            {savedSearchLoading || quickSearchLoading ? (
                <PulseLoader />
            ) : (
                <>
                    {isSavedSearch && (
                        <IconRouter aria-hidden name="filter" className={classes.icon} />
                    )}

                    <div className={classes.title}>{T('noSearchResults.title')}</div>

                    {showLinkedinWarning ? (
                        <LinkedinWarning classes={classes} />
                    ) : (
                        <div className={classes.subtitle}>
                            <span>
                                {T(
                                    'noSearchResults.subtitle' +
                                        (isSavedSearch ? 'Saved' : 'Quick'),
                                )}
                            </span>

                            {!noFiltersApplied && (
                                <span>
                                    <span>
                                        {T(
                                            'noSearchResults.try' +
                                                (isSavedSearch ? 'Saved' : 'Quick'),
                                        )}
                                    </span>
                                    <button
                                        className={classes.link}
                                        onClick={() => dispatch(resetFilters({reset: true}))}
                                    >
                                        {T('noSearchResults.clearFilters')}
                                    </button>
                                </span>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

NoResultsMsg.propTypes = {
    isSavedSearch: PropTypes.bool,
    noResults: PropTypes.bool.isRequired,
    percentComplete: PropTypes.number,
};

export default NoResultsMsg;
