import {useCallback, useEffect, useState} from 'react';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';

import IconRouter from 'hsi/components/IconRouter';

import {T} from 'hsi/i18n';

import useStyles from './styles';

const variantIcon = {
    success: 'success',
    warning: 'c-warning',
    info: 'c-info',
};

type BannerProps = {
    animate?: boolean;
    children: React.ReactNode;
    className?: string;
    hidable?: boolean;
    iconSize?: 'small' | 'regular';
    noMargin?: boolean;
    onHide?: () => void;
    show?: boolean;
    variant?: keyof typeof variantIcon;
};

const Banner = ({
    animate = false,
    children,
    className,
    hidable = false,
    iconSize = 'regular',
    noMargin = false,
    onHide,
    show: defaultShow = true,
    variant = 'info',
}: BannerProps) => {
    const {classes, cx} = useStyles();
    const [show, setShow] = useState(defaultShow);

    useEffect(() => {
        typeof defaultShow !== 'undefined' && setShow(defaultShow);
    }, [defaultShow, setShow]);

    const handleHide = useCallback(() => {
        onHide?.() || setShow(false);
    }, [onHide, setShow]);

    if (!show && !animate) return null;

    const content = (
        <div
            className={cx(
                classes.banner,
                classes[variant],
                className,
                noMargin && classes.noMargin,
            )}
            data-testid="banner"
        >
            <div
                className={cx(
                    classes[`${variant}StatusIconWrapper`],
                    classes[`${iconSize}StatusIconWrapper`],
                )}
                data-testid="bannerIcon"
            >
                <IconRouter
                    aria-hidden
                    className={cx(
                        classes[`${iconSize}StatusIcon`],
                        classes[`${variant}StatusIcon`],
                    )}
                    name={variantIcon[variant]}
                />
            </div>
            <div className={classes.content}>{children}</div>
            {hidable && (
                <div className={classes.closeIconWrapper}>
                    <IconButton aria-label={T('dialog.close')} onClick={handleHide} size="large">
                        <IconRouter className={classes.closeIcon} name="mui-close" />
                    </IconButton>
                </div>
            )}
        </div>
    );

    return animate ? (
        <Collapse data-testid="bannerAnimate" in={show}>
            {content}
        </Collapse>
    ) : (
        content
    );
};

export default Banner;
