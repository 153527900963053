import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import img from 'hsi/img/floating-beach.png';

//Components
import PulseLoader from 'hsi/components/PulseLoader';
import {Dialog, DialogContent, DialogActions} from 'hsi/components/Dialog';
import Button from 'hsi/components/Button';

//Actions
import {hideNotification} from 'hsi/actions/notificationsActions';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';

//The component
export default function HSILoadingScreen() {
    const classes = useStyles();

    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const {message} = useSelector((state) => state.notifications);

    const onClose = () => setOpen(false);
    const onClosed = () => dispatch(hideNotification());

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onClosed={onClosed}
            className={classes.HSILoadingModal}
        >
            <DialogContent>
                <div className={'loadingScreen'}>
                    <div className="wrapper">
                        <div className="loader-container">
                            <PulseLoader size="large" className={'lightGrey'} />
                        </div>
                        <div className="notification" role="status">
                            {message || T('exportToPDF.notificationTitle')}
                        </div>
                        <img src={img} className="image" alt={'something'} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} classes={{label: 'secondary'}}>
                    {T('exportToPDF.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
