
//Entry configuration
import theme from 'hsi/themes/hsi';
import config from 'hsi/configurations/hsi/config';

//Utils
import entryFactory from 'hsi/utils/entry-factory';

//Init the app
entryFactory('', config, theme, {});
