import failImage from 'hsi/img/401cat.png';
import noResultsImg from 'hsi/themes/hsi/img/no-results.png';
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

const elements = (theme: ThemeBuilder) => {
    return {
        searchBarContainerHeight: '72px',
        navBarContainerHeight: '72px',
        mentionsDrawerWidth: '470px',
        failImage,
        noResultsImg,
    };
};

export default elements;
