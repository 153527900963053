const colors = {
    lightGrey100: '#E3DDD8',
    lightGrey80: '#E9E4E0',
    lightGrey60: '#EEEBE8',
    lightGrey50: '#DCD5D0',
    lightGrey40: '#F4F1EF',
    lightGrey20: '#F9F8F7',
    lightGrey10: '#FCFCFB',
    darkGrey100: '#241F21',
    darkGrey80: '#504C4D',
    darkGrey60: '#7C797A',
    darkGrey40: '#A7A5A6',
    darkGrey20: '#D3D2D3',
    darkGrey10: '#E9E9E9',
    secondaryGreen100: '#28863E',
    secondaryGreen80: '#539E65',
    secondaryGreen60: '#7EB68B',
    secondaryGreen40: '#A9CFB2',
    secondaryGreen20: '#D4E7D8',
    secondaryGreen10: '#EAF3EC',
    secondaryPurple100: '#543D80',
    secondaryPurple80: '#766499',
    secondaryPurple60: '#988BB3',
    secondaryPurple40: '#BBB1CC',
    secondaryPurple20: '#DDD8E6',
    secondaryPurple10: '#EEECF2',
    primaryRed100: '#BA3038',
    primaryRed80: '#C85960',
    primaryRed60: '#D68388',
    primaryRed40: '#E3ACAF',
    primaryRed20: '#F1D6D7',
    primaryRed10: '#F8EAEB',
    secondaryOrange100: '#F37021',
    secondaryOrange80: '#F58C4D',
    secondaryOrange60: '#F8A97A',
    secondaryOrange40: '#FAC6A6',
    secondaryOrange20: '#FDE2D3',
    secondaryOrange10: '#FEF1E9',
    secondaryBlue100: '#2F6B9A',
    secondaryBlue80: '#5989AE',
    secondaryBlue60: '#82A6C2',
    secondaryBlue40: '#ACC4D7',
    secondaryBlue20: '#D5E1EB',
    secondaryBlue10: '#EAF0F5',
    primaryYellow100: '#FBA919',
    primaryYellow80: '#FCBB47',
    primaryYellow60: '#FCCC75',
    primaryYellow40: '#FDDDA3',
    primaryYellow20: '#FEEED1',
    primaryYellow10: '#FFF6E8',
    primaryBlue100: '#143059',
    primaryBlue80: '#43597A',
    primaryBlue60: '#72839B',
    primaryBlue40: '#A1ACBD',
    primaryBlue30: '#B0B9C5',
    primaryBlue20: '#D0D6DE',
    primaryBlue10: '#E8EAEE',
    recentSearchesBg: '#EAEFF5',
    icons: '#153158',
    buttonHover: '#B0B9C5',
    white: '#FFFFFF',
    // sentiment
    positive: '#2a853f',
    neutral: '#d3d2d3',
    negative: '#bb3038',

    // emotion
    anger: '#d78388',
    disgust: '#756499',
    fear: '#ff7600',
    joy: '#fcbb48',
    sadness: '#72839b',
    surprise: '#539e65',

    // gender
    female: '#C85960',
    male: '#543D80',
    //special ones
    fbcolor: '#1877F2',
    //made this one up. TODO: ask HSI
    fbcolorActive: '#659ef6',
    snackBarCloseButtonBg: '#f5f8f5',
    keyline: '#0D2F5C',
    textMeta: '#2F3538',
    borders: '#F4F1EF',
    textDefault: '#241F21',

    errorBg: '#f9bfbf',

    error: '#BA3038', //primaryRed100
    success: '#539E65', //secondaryGreen80
    warning: '#FBA919', //primaryYellow100

    //platforms
    linkedin: '#0A66C2',
    linkedinHovered: '#1974cf',
} as const;

export default colors;
