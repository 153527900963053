import http, { HttpResult } from "hsi/classes/Http";
import { QueryData } from "hsi/types/queryData";


export async function getQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T): Promise<HttpResult<QueryData[T] | null>> {
    return http.get<QueryData[T]>(`/fe-api/query-data/${projectId}/${queryId}/${key}`);
}

export async function putQueryDataKey<T extends keyof QueryData>(queryId: number, projectId: number, key: T, value: QueryData[T] | null): Promise<HttpResult<void>> {
    return http.put<void>(`/fe-api/query-data/${projectId}/${queryId}/${key}`, {value});
}