import React, {memo} from 'react';

const icons = {
    up: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#D4E7D8"
            />
            <path
                d="M11 4.5H7V5.5H9.793L4.6465 10.6465L5.3535 11.3535L10.5 6.207V9H11.5V5C11.5 4.724 11.276 4.5 11 4.5Z"
                fill="#28863E"
                stroke="#28863E"
            />
        </>
    ),
    down: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#F1D6D7"
            />
            <path
                d="M11 11.5H7V10.5H9.793L4.6465 5.3535L5.3535 4.6465L10.5 9.793V7H11.5V11C11.5 11.276 11.276 11.5 11 11.5Z"
                fill="#BA3038"
                stroke="#BA3038"
            />
        </>
    ),
    equal: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#D3D2D3"
            />
            )
            <path
                d="M4.544 6.696V5.032H11.904V6.696H4.544ZM4.544 10.392V8.728H11.904V10.392H4.544Z"
                fill="#504C4D"
            />
        </>
    ),
};

export default memo(function TrendingIcon({trend, targetX, y, height}) {
    return (
        <g className="trendingArrow" transform={`translate(${targetX + 7}, ${y + height - 20})`}>
            {icons[trend]}
        </g>
    );
});
