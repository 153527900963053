import {
    ALL_UPDATE_FILTERS_ACTIONS,
    CLEAR_RESULTS,
    LOADING_DATA,
    MENTIONS_DRILL_IN,
    PEAKS_LOADED,
    SHOW_NO_RESULTS,
    TOGGLE_CONFIG,
    TOTAL_VOLUME_CONFIG_UPDATE,
    UPDATE_DATERANGE,
    WORD_CLOUD_CONFIG_UPDATE,
    SUBMIT_QUERY,
} from 'hsi/constants/actionTypes';

const initialState = {
    noResults: false,
    peaks: {},
    sideDrawer: {
        open: false,
        type: 'wordCloud',
    },
};

const resultReducer = (state = initialState, action) => {
    //Any time filters change, assume there might be results (code elsewhere will be re-checking)
    if (
        action.type === UPDATE_DATERANGE ||
        action.type === SUBMIT_QUERY ||
        ALL_UPDATE_FILTERS_ACTIONS.includes(action.type)
    ) {
        const newState = {...state, noResults: false};
        return newState;
    }

    switch (action.type) {
        case CLEAR_RESULTS:
            return initialState;
        case SHOW_NO_RESULTS:
            const newState = {...state, noResults: action.payload};

            return newState;
        case LOADING_DATA:
            return {
                ...state,
                peaks: {
                    ...state.peaks,
                    [action.payload.type]: null,
                },
            };
        case PEAKS_LOADED:
            return {
                ...state,
                peaks: {...state.peaks, [action.payload.type]: action.payload.peaks},
            };
        case MENTIONS_DRILL_IN:
            return {...state, sideDrawer: {...state.sideDrawer, open: false}};
        case TOGGLE_CONFIG:
            return {...state, sideDrawer: {...state.sideDrawer, ...action.payload}};
        case WORD_CLOUD_CONFIG_UPDATE:
            return {
                ...state,
                sideDrawer: {
                    ...state.sideDrawer,
                    wordCloud: {...state.sideDrawer.wordCloud, ...action.payload},
                },
            };
        case TOTAL_VOLUME_CONFIG_UPDATE:
            return {
                ...state,
                sideDrawer: {
                    ...state.sideDrawer,
                    totalVolume: {...state.sideDrawer.totalVolume, ...action.payload},
                },
            };
        default:
            return state;
    }
};

export default resultReducer;
