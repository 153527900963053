import {
    LOADING_LANGUAGES,
    LOADED_LANGUAGES,
    LOAD_LANGUAGES_ERROR,
    LOADING_LOCATIONS,
    LOADED_LOCATIONS,
    LOAD_LOCATIONS_ERROR,
    RESET_LOCATIONS,
    LOADING_CATEGORIES,
    LOADED_CATEGORIES,
    LOAD_CATEGORIES_ERROR,
    LOADING_ASSIGNMENT,
    LOADED_ASSIGNMENT,
    LOAD_ASSIGNMENT_ERROR,
} from '../constants/actionTypes';
import {find} from 'lodash';
import {showNotification} from 'hsi/actions/notificationsActions';
import {T} from 'hsi/i18n';
import autocompleteService from '../services/autocompleteService';
import {ThunkType} from 'hsi/types/redux';

export function loadLanguages(): ThunkType {
    return (dispatch, getState) => {
        const {
            autocomplete: {languageAutocomplete},
        } = getState();

        if (languageAutocomplete.results.length === 0) {
            dispatch({
                type: LOADING_LANGUAGES,
                payload: {loading: true},
            });

            return autocompleteService.loadLanguages().then((res) => {
                dispatch({
                    type: LOADED_LANGUAGES,
                    payload: res,
                });
            }).catch((e) => {
                console.error('load languages error', e);
                dispatch(
                    showNotification({
                        message: T('autocomplete.languages.loadingError'),
                        hidable: true,
                        variant: 'warning',
                    }),
                );
                dispatch({type: LOAD_LANGUAGES_ERROR, payload: e});});;
        } else {
            return Promise.resolve();
        }
    };
}

export function loadLocations(projectId: any, prefix?: string): ThunkType {
    return (dispatch) => {
        dispatch({
            type: LOADING_LOCATIONS,
            payload: {loading: true},
        });

        return autocompleteService
            .loadLocations(prefix)
            .then((res) => {
                dispatch({
                    type: LOADED_LOCATIONS,
                    payload: res,
                });
            })
            .catch((e) => {
                console.error('load locations error', e);
                dispatch(
                    showNotification({
                        message: T('autocomplete.locations.loadingError'),
                        hidable: true,
                        variant: 'warning',
                    }),
                );
                dispatch({type: LOAD_LOCATIONS_ERROR});}); //TODO actually handle this error in some way?
    };
}

export const resetLocations = () => ({type: RESET_LOCATIONS});

export function loadCategories(projectId?: number): ThunkType {
    return (dispatch, getState) => {
        projectId = projectId || getState().query.context.projectId;
        dispatch({type: LOADING_CATEGORIES, payload: true});
        return autocompleteService
            .loadCategories(projectId!)
            .then((data) => {
                dispatch({
                    type: LOADED_CATEGORIES,
                    payload: data.results,
                });
            })
            .catch((e) => {
                console.error('load categories error', e);
                dispatch({type: LOAD_CATEGORIES_ERROR});
            });
    };
}

export function loadAssignment(projectId?: number): ThunkType {
    return (dispatch, getState) => {
        projectId = projectId || getState().query.context.projectId;
        dispatch({type: LOADING_ASSIGNMENT, payload: true});
        return autocompleteService
            .loadWorkflow(projectId!)
            .then((data) => {
                dispatch({
                    type: LOADED_ASSIGNMENT,
                    payload: find(data, (item) => item.name === 'Assignment').children.map(
                        (c: any) => c.name,
                    ),
                });
            })
            .catch((e) => {
                console.error('load assignment error', e);
                dispatch({type: LOAD_ASSIGNMENT_ERROR});
            });
    };
}
