import typeToFormatter from 'hsi/utils/formatter';
import resultService from 'hsi/services/resultService';

import {hasResults} from 'hsi/utils/misc';
import {TOTAL_VOLUME_TYPES} from 'hsi/constants/config';
import {LinkedinChannelIdsType, PageType} from 'hsi/types/shared';
import {QueryContextType} from 'hsi/types/query';
import {APIFilterFormat, Breakdowns, DateRange} from 'hsi/types/filters';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {ChartKey} from 'hsi/types/charts';
import { ProcessedCardRules } from 'hsi/utils/cards/processCardRules';



export default function loadData(
    queryContext: QueryContextType,
    type: ChartKey,
    apiFilterParams: APIFilterFormat,
    dateRange: DateRange,
    additionalQueries: number[],
    cardPersistState: RootReducer['cardPersistState'],
    orderBy: string | undefined | null,
    limit: number | undefined,
    linkedinChannelIds: LinkedinChannelIdsType,
    rules: ProcessedCardRules | null | undefined,
    config: {
        themeColors: {[key: string]: any};
        pageTypes: PageType[];
    },
): Promise<{data: any; noData: boolean | null}> {
    const hasAdditionalCall = !!rules?.additionalCall;

    //start loading data
    const dataLoadPromise = resultService.loadData({
        queryContext,
        type,
        apiFilterParams,
        dateRange,
        additionalQueries,
        orderBy,
        linkedinChannelIds,
        cardPersistState,
        limit,
    });

    const additionalDataLoadPromise = hasAdditionalCall
        ? resultService.loadData({
              queryContext,
              type: rules.additionalCall!,
              apiFilterParams,
              dateRange,
              additionalQueries,
              orderBy,
              linkedinChannelIds,
              cardPersistState,
          })
        : Promise.resolve(null);

    return Promise.all([dataLoadPromise, additionalDataLoadPromise]).then(
        ([data, otherResults]) => {
            //handle data loaded
            return {
                data: typeToFormatter[type as keyof typeof typeToFormatter]({
                    data,
                    otherResults,
                    config: config as any,
                    breakdown: cardPersistState?.[type as keyof Breakdowns]?.breakdown,
                    additionalQueries,
                    queryContext,
                    linkedinChannelIds,
                    limit,
                }),
                noData: hasNoData(type, queryContext.isSavedSearch, data),
            };
        },
    );
}

function hasNoData(type: string, isSavedSearch: boolean, data: any): boolean | null {
    //If there's no history data, there's no data so we hide everything
    if (
        ['totalMentions', 'totalVolume'].includes(type) &&
        ((!isSavedSearch && !data?.mentionsCount) || (isSavedSearch && !checkHistoryData(data)))
    ) {
        return true;
    } else if (
        ['totalMentions', 'totalVolume'].includes(type) &&
        ((!isSavedSearch && data?.mentionsCount) || (isSavedSearch && checkHistoryData(data)))
    ) {
        return false;
    }

    return null;
}

//Could use better types...
function checkHistoryData(data: {[key: string]: any}) {
    let hasData = false;
    const cats = TOTAL_VOLUME_TYPES.map(({id}) => id);

    for (let cat of cats) {
        hasData = hasData ? true : hasResults(data[cat]?.current?.results);
    }

    return hasData;
}
