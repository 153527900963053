import {useCallback, useMemo, useState} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Autocomplete from 'hsi/components/AutocompleteLegacy';
import Banner from 'hsi/components/Banner';
import Button from 'hsi/components/Button';
import Checkbox from 'hsi/components/Checkbox';
import ChipInput from 'hsi/components/ChipInput';
import DialogContainer from 'hsi/containers/DataManagement/AddDataSourceDialog/DialogContainer';
import LabelledButton from 'hsi/components/Button/LabelledButton';
import IconRouter from 'hsi/components/IconRouter';
import Markdown from 'hsi/components/Markdown';
import Popover, {sizeToOpener} from 'hsi/components/Popover';

import {useInstagramAccounts, useInstagramHashtags} from 'hsi/hooks/useDataSource';
import useStyles from './styles';
import useUniqueId from 'hsi/hooks/useUniqueId';

import {T} from 'hsi/i18n';
import tns from 'hsi/i18n/tns';
import {formatIgHashtag, unformatIgHashtag} from 'hsi/utils/hashtags';

import {InstagramAccountItem, InstagramHashtagItem} from 'hsi/types/dataManagement';

const t = tns('dataManagement.addDataSourcesDialog.addInstagramHashtags');

type AddInstagramHashtagsProps = {
    onBack: () => void;
    onClose: () => void;
    onNoAuthentications: () => void;
};

const AddInstagramHashtags = ({
    onBack,
    onClose,
    onNoAuthentications,
}: AddInstagramHashtagsProps) => {
    const {classes} = useStyles();
    const selectAccountLabelId = useUniqueId(null, 'selectAccountLabelId');
    const instagramAccounts = useInstagramAccounts({defaultSize: 1000, defaultOrderBy: 'owned'});
    const instagramHashtags = useInstagramHashtags();

    const [acknowledge, setAcknowledge] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<InstagramAccountItem>();

    const [hashtagError, setHashtagError] = useState(false);
    const [hashtags, setHashtags] = useState<string[]>([]);

    const isAddDisabled = useMemo(
        () =>
            !selectedAccount ||
            !hashtags.length ||
            hashtagError ||
            !acknowledge ||
            instagramHashtags.isLoading,
        [acknowledge, hashtagError, hashtags.length, instagramHashtags.isLoading, selectedAccount],
    );

    const onHashtagsChange = useCallback((newValue: string[]) => {
        const newHashtags = newValue.map(formatIgHashtag);
        const uniqueHashtags = [...new Set(newHashtags)];

        setHashtagError(false);
        setHashtags(uniqueHashtags);
    }, []);

    const validateHashtags = useCallback(
        (values: string[]) => {
            const duplicateHashtags = values.filter((value) =>
                (instagramHashtags?.data as InstagramHashtagItem[])
                    ?.filter((hashtag) => hashtag.linkedAccountId === selectedAccount?.id)
                    ?.map((hashtag) => hashtag.name)
                    .includes(unformatIgHashtag(value)),
            );
            const newErrors = duplicateHashtags.map((value) => ({
                label: 'dataManagement.addDataSourcesDialog.addInstagramHashtags.selectHashtagErrorTooltip',
                reason: 'Hashtag is already tracked',
                value,
            }));

            newErrors.length && setHashtagError(true);
            return newErrors;
        },
        [instagramHashtags?.data, selectedAccount?.id],
    );

    const onSubmit = useCallback(
        (event: {preventDefault: () => void}) => {
            event.preventDefault();

            if (!isAddDisabled) {
                instagramHashtags.add.mutate({
                    hashtags: hashtags.map(unformatIgHashtag),
                    instagramBusinessAccountId: selectedAccount?.id,
                });
                onClose();
            }
        },
        [hashtags, instagramHashtags.add, isAddDisabled, onClose, selectedAccount],
    );

    return (
        <DialogContainer
            actions={
                <>
                    <Button className={classes.backButton} onClick={onBack} priority="secondary">
                        {T('back')}
                    </Button>
                    <Button onClick={onClose} priority="primary">
                        {T('cancel')}
                    </Button>
                    <Button disabled={isAddDisabled} onClick={onSubmit} priority="cta">
                        {t('addButton')}
                    </Button>
                </>
            }
            onClose={onClose}
            title={t('title')}
        >
            <form className={classes.container} onSubmit={onSubmit}>
                <label id={selectAccountLabelId} className={classes.accountLabel}>
                    {t('selectAccountLabel')}
                </label>
                <Popover
                    content={({close}) => (
                        <>
                            <Autocomplete
                                fullWidth
                                getOptionLabel={(option: InstagramAccountItem) => option.name}
                                getOptionDisabled={(option: InstagramAccountItem) =>
                                    option.hashtagsRemaining === 0
                                }
                                open
                                options={instagramAccounts?.activeOwned || []}
                                onChange={(_e, newValue: InstagramAccountItem) => {
                                    setSelectedAccount(newValue);
                                    close();
                                }}
                                renderOptionComponent={(props) =>
                                    renderOptionComponent({
                                        className: classes.accountInputOptionLeft,
                                        ...props,
                                    })
                                }
                            />
                            <Button
                                className={classes.accountInputCta}
                                fullWidth
                                onClick={onNoAuthentications}
                                priority="secondary"
                            >
                                <IconRouter className={classes.accountInputCtaIcon} name="f-add" />
                                {t('selectAccountCta')}
                            </Button>
                        </>
                    )}
                    size={sizeToOpener}
                >
                    <LabelledButton
                        className={classes.accountInput}
                        endIcon={<ExpandMoreIcon />}
                        fullWidth
                        label={
                            <span
                                aria-labelledby={selectAccountLabelId}
                                className={classes.accountInputLabel}
                            >
                                {selectedAccount?.name || t('selectAccountPlaceholder')}
                            </span>
                        }
                        priority="primary"
                    />
                </Popover>
                <ChipInput
                    className={classes.hashtagInput}
                    disabled={!selectedAccount}
                    error={hashtagError}
                    getInputErrorMessage={() => ({
                        title: t('selectHashtagErrorTitle'),
                        desc: t('selectHashtagErrorDesc'),
                    })}
                    helperText={t('selectHashtagHelpText')}
                    label={t('selectHashtagLabel')}
                    itemName={t('selectHashtagItemName')}
                    isAutoSelect={true}
                    maxLength={selectedAccount?.hashtagsRemaining || 30}
                    onValuesChange={onHashtagsChange}
                    placeholder={t('selectHashtagPlaceholder')}
                    validateValues={validateHashtags}
                    values={hashtags}
                    splitInputOn={/,+|\s+/}
                />
                <Checkbox
                    className={classes.acknowledgeCheckbox}
                    children={t('acknowledge')}
                    onChange={() => setAcknowledge((currentState) => !currentState)}
                    checked={acknowledge}
                />
                <Banner className={classes.infoBanner}>{t('infoBanner')}</Banner>
            </form>
        </DialogContainer>
    );
};

interface renderOptionComponentProps {
    className: string;
    option: any;
    parts: any;
}

const renderOptionComponent = ({className, option, parts}: renderOptionComponentProps) => (
    <>
        <div>
            {parts.map((part: any, index: number) => {
                if (part.highlight) {
                    return <strong key={index}>{part.text}</strong>;
                } else {
                    return <span key={index}>{part.text}</span>;
                }
            })}
        </div>
        <span className={className}>
            <Markdown>{T('left', {num: option?.hashtagsRemaining, name: t('type')})}</Markdown>
        </span>
    </>
);

export default AddInstagramHashtags;
