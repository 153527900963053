// http://figma.com/file/xkxln1z4bJMM8jsyq3sMnz/Tapas_Basic_2020?node-id=5%3A6

import React from 'react';
import Fade from '@mui/material/Fade';

//Components
import {BlankIcon, CheckedIcon, IndeterminateIcon} from './components/checkbox-icons';
import './components/checkbox-icons/styles';
import IconRouter from 'hsi/components/IconRouter';
import * as components from './components';
import colors from './colors';
import elements from './elements';

//Classes
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

//Other
import baseTheme from 'hsi/themes/_base';
import {Colors, ThemeV1} from 'hsi/types/theme';

//The theme
const theme = new ThemeBuilder(
    'HSI',
    false,
    {
        components,
        colors: function () {
            return (
                ThemeBuilder.nullMerge<Partial<Colors>>(colors, this._getExtendedValue('colors')) ||
                {}
            );
        },
        font: () => ({
            url: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese',
            name: "'Source Sans Pro', sans-serif",
            bold: 700,
            semiBold: 600,
            regular: 400,
        }),
        mixin: function (theme) {
            const colors = theme.colors as ThemeV1['colors'];
            const baseMixins = this._getExtendedValue('mixin');

            const mixins: any = {
                linkColor: {
                    color: colors.secondaryBlue100,
                    fill: colors.secondaryBlue100,
                    '&:visited': {
                        color: colors.secondaryBlue100,
                        fill: colors.secondaryBlue100,
                    },
                    '&:hover': {
                        color: colors.secondaryBlue100,
                        fill: colors.secondaryBlue100,
                    },
                    '&:active': {
                        color: colors.secondaryBlue100,
                        fill: colors.secondaryBlue100,
                    },
                },
            };

            mixins.linkButton = {
                ...baseMixins.blankButton,
                ...mixins.linkColor,

                fontSize: 'inherit',
                display: 'inline',

                '&:hover': {
                    textDecoration: 'underline',
                },

                '&:focus': {
                    textDecoration: 'underline',
                },
            };

            return ThemeBuilder.nullMerge(mixins, baseMixins);
        },
        globals: function (theme) {
            const colors = theme.colors as ThemeV1['colors'];
            const {font} = theme;

            return ThemeBuilder.nullMerge(
                {
                    'html,body': {
                        fontFamily: font.name,
                        color: colors.darkGrey100,
                        backgroundColor: colors.lightGrey100,
                        fontSize: '0.875rem',
                        lineHeight: 1.43,
                        letterSpacing: '0.01071em',
                    },

                    input: {
                        '&::-webkit-input-placeholder, &::placeholder': {
                            fontStyle: 'italic !important',
                            fontWeight: '400 !important',
                            color: `${colors.darkGrey60} !important`,
                            fontSize: '16px !important',
                            opacity: '1 !important',
                        },
                        '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                            fontStyle: 'italic !important',
                            fontWeight: '400 !important',
                            color: `${colors.darkGrey60} !important`,
                            fontSize: '16px !important',
                            opacity: '1 !important',
                        },
                    },
                    textarea: {
                        '&::-webkit-input-placeholder, &::placeholder': {
                            fontStyle: 'italic !important',
                            fontWeight: '400 !important',
                            color: `${colors.darkGrey60} !important`,
                            fontSize: '16px !important',
                            opacity: '1 !important',
                        },
                        '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                            fontStyle: 'italic !important',
                            fontWeight: '400 !important',
                            color: `${colors.darkGrey60} !important`,
                            fontSize: '16px !important',
                            opacity: '1 !important',
                        },
                    },

                    '.recharts-legend-wrapper': {
                        paddingTop: '20px',
                        '& ul.recharts-default-legend': {
                            position: 'relative',
                            '& .recharts-legend-item': {
                                display: 'inline-flex',
                                margin: '0 8px',
                                lineHeight: '20px',
                                '& .recharts-surface': {
                                    flex: '0 0 12px',
                                    width: '12px',
                                    height: '12px',
                                    alignSelf: 'center',
                                    borderRadius: '50%',
                                    marginRight: '0 !important',
                                },
                                '& .recharts-legend-item-text': {
                                    flex: '1 1 auto',
                                    fontSize: '12px',
                                    paddingLeft: '5px',
                                    color: colors.darkGrey100,
                                    maxWidth: '200px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                },
                            },
                        },
                    },

                    '.save-search-cta': {
                        width: '150px',
                        fontSize: '14px',
                        textAlign: 'left',
                        fontWeight: 400,
                        color: colors.darkGrey100,

                        '& span, & a': {
                            color: '#007faf',
                            fontWeight: font.semiBold,
                        },
                        '& .read-only': {
                            span: {
                                color: colors.darkGrey100,
                            },
                        },
                    },

                    '.video-react-big-play-button': {
                        top: '50% !important',
                        left: '50% !important',
                        transform: 'translate(-50%, -50%)',
                    },
                    //primaryBlue10
                    '.grey-select-field': {
                        borderRadius: '2px !important',
                        '& .MuiInputBase-formControl': {
                            backgroundColor: `${colors.primaryBlue20}`,
                            borderRadius: '2px !important',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid transparent !important',
                            },
                            '& .MuiOutlinedInput-input': {
                                paddingLeft: '28px',
                                color: colors.primaryBlue100,
                            },
                            '& input': {
                                fontWeight: 'bold !important',
                                '&::-webkit-input-placeholder, &::placeholder': {
                                    opacity: 1,
                                    fontStyle: 'normal !important',
                                    fontWeight: 'bold !important',
                                    color: `${colors.primaryBlue100} !important`,
                                },
                                '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder': {
                                    opacity: 1,
                                    fontStyle: 'normal !important',
                                    fontWeight: 'bold !important',
                                    color: `${colors.primaryBlue100} !important`,
                                },
                            },

                            '&.Mui-disabled': {
                                backgroundColor: `${colors.primaryBlue10}`,
                                '& input': {
                                    '&::-webkit-input-placeholder, &::placeholder': {
                                        opacity: 1,
                                        fontStyle: 'normal !important',
                                        fontWeight: 'bold !important',
                                        color: `${colors.primaryBlue40} !important`,
                                    },
                                    '&:-moz-placeholder, &::-moz-placeholder,&:-ms-input-placeholder':
                                        {
                                            opacity: 1,
                                            fontStyle: 'normal !important',
                                            fontWeight: 'bold !important',
                                            color: `${colors.primaryBlue40} !important`,
                                        },
                                },
                            },
                        },
                    },
                },
                this._getExtendedValue('globals'),
            );
        },

        elements: function (theme) {
            return ThemeBuilder.nullMerge(elements(theme), this._getExtendedValue('elements'));
        },

        overrides: function (theme) {
            const colors = theme.colors as ThemeV1['colors'];
            const {font} = theme;

            return ThemeBuilder.nullMerge(
                {
                    /********     BUTTON    ********/
                    MuiButton: {
                        root: {
                            padding: '0 24px',
                            height: '44px',
                            lineHeight: '1.2',
                            fontSize: '14px !important',
                            borderRadius: '2px',
                            fontWeight: '700',
                            textTransform: 'capitalize',
                        },
                        contained: {
                            boxShadow: 'none',
                            backgroundColor: colors.primaryBlue20,
                            color: colors.primaryBlue100,
                            border: '0 solid transparent',
                            transition:
                                'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',
                            '&:hover': {
                                color: colors.primaryBlue100,
                                backgroundColor: colors.buttonHover,
                                boxShadow: 'none',
                            },
                            '&:active': {
                                boxShadow: 'none',
                                backgroundColor: colors.primaryBlue40,
                            },
                            '&:focus': {
                                backgroundColor: colors.buttonHover,
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&.Mui-disabled': {
                                border: `1px solid rgba(0, 0, 0, 0.12)`,
                            },
                        },
                        containedPrimary: {
                            color: colors.primaryBlue100,
                            backgroundColor: colors.primaryYellow100,
                            border: `0px solid transparent`,
                            '&:hover': {
                                backgroundColor: colors.primaryYellow60,
                                color: colors.primaryBlue100,
                                boxShadow: 'none',
                            },
                            '&:active': {
                                backgroundColor: colors.primaryYellow80,
                                color: colors.primaryBlue100,
                                boxShadow: 'none',
                            },
                            '&:focus': {
                                backgroundColor: colors.primaryYellow60,
                                color: colors.primaryBlue100,
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&.Mui-disabled': {
                                color: colors.primaryBlue40,
                                backgroundColor: colors.primaryYellow10,
                            },
                        },
                        containedSecondary: {
                            boxShadow: 'none',
                            backgroundColor: colors.primaryBlue20,
                            color: colors.primaryBlue100,
                            border: '0 solid transparent',
                            transition:
                                'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',
                            '&:hover': {
                                color: colors.primaryBlue100,
                                backgroundColor: colors.buttonHover,
                                boxShadow: 'none',
                            },
                            '&:active': {
                                boxShadow: 'none',
                                backgroundColor: colors.primaryBlue40,
                            },
                            '&:focus': {
                                backgroundColor: colors.buttonHover,
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&.Mui-disabled': {
                                border: `1px solid rgba(0, 0, 0, 0.12)`,
                            },
                        },

                        text: {
                            transition:
                                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            padding: '0 24px',
                            color: colors.primaryBlue100,
                            border: `1px solid ${colors.secondaryBlue20}`,
                            backgroundColor: colors.lightGrey10,
                            '&:hover': {
                                color: colors.primaryBlue100,
                                backgroundColor: colors.buttonHover,
                                boxShadow: 'none',
                            },
                            '&:active': {
                                boxShadow: 'none',
                                backgroundColor: colors.primaryBlue40,
                            },
                            '&:focus': {
                                backgroundColor: colors.buttonHover,
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&.Mui-disabled': {
                                border: `1px solid rgba(0, 0, 0, 0.12)`,
                            },
                        },
                        sizeSmall: {
                            fontSize: '12px',
                            lineHeight: '16px',
                            textTransform: 'uppercase',
                            padding: '4px 8px',
                            height: 44,
                        },
                        iconSizeSmall: {
                            '& svg': {
                                width: 12,
                                height: 12,
                            },
                        },
                    },

                    /********   BUTTON GROUP    *******/
                    MuiButtonGroup: {
                        grouped: {
                            color: colors.primaryBlue100,
                            border: `0px solid transparent`,
                            backgroundColor: colors.primaryYellow100,
                            '&:not(:last-child)': {
                                borderRight: 'none',
                            },
                            '&:not(:first-child)': {
                                borderLeft: 'none',
                                marginLeft: '0px',
                            },
                            '&:hover': {
                                backgroundColor: colors.primaryYellow60,
                                color: colors.primaryBlue100,
                                boxShadow: 'none',
                            },
                        },
                        groupedOutlinedHorizontal: {
                            '&:not(:first-child)': {
                                marginLeft: '0px',
                            },
                        },
                    },

                    /********   ICON BUTTON    *******/
                    MuiIconButton: {
                        root: {
                            color: colors.icons,
                            borderWidth: '0',
                            borderStyle: 'solid',
                            borderColor: 'transparent',
                            padding: '14px',
                            transition:
                                'background-color 0.15s ease-out 0s, border-color 0.1s ease-out 0s, box-shadow 0.1s ease-out 0s',

                            '&.Mui-disabled': {
                                color: colors.primaryBlue40,
                                backgroundColor: colors.primaryBlue10,

                                '& svg': {
                                    fill: 'currentColor',
                                },
                            },

                            '& svg': {
                                fill: colors.icons,
                            },
                            '&:hover,&:focus-visible,&.active': {
                                backgroundColor: colors.primaryBlue20,
                            },
                            '&:focus-visible': {
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&:active': {
                                backgroundColor: colors.primaryBlue30,
                            },
                        },
                        sizeSmall: {
                            width: 32,
                            height: 32,
                        },
                    },

                    /********  OUTLINED INPUT  *********/
                    MuiOutlinedInput: {
                        root: {
                            backgroundColor: colors.lightGrey10,
                            borderRadius: '0px',
                            lineHeight: '28px',
                            '&.Mui-focused': {
                                '& .notchedOutline': {
                                    boxShadow: 'none',
                                    border: `1px solid ${colors.darkGrey60}`,
                                },
                            },
                            '&.Mui-error': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: `2px solid ${colors.primaryRed100} !important`,
                                },
                            },
                            '& .app-icon.icon-chevron-down': {
                                position: 'absolute',
                                right: '7px',
                                top: 'calc(50% - 7px)',
                                pointerEvents: 'none',
                                fill: colors.primaryBlue100,
                            },
                        },
                        input: {
                            fontSize: '16px',
                            padding: '8px 8px',
                            height: 'auto',
                            fontWeight: font.semiBold,
                        },
                        multiline: {
                            fontSize: '16px',
                            padding: '8px 8px',
                            height: 'auto',
                            fontWeight: font.semiBold,
                        },
                        inputMultiline: {
                            height: 'auto',
                        },
                        notchedOutline: {
                            border: `1px solid ${colors.darkGrey60} !important`,
                            borderRadius: '0px',
                            top: 0,
                            '& legend': {
                                display: 'none !important',
                            },
                        },
                    },

                    /****  FORM LABEL (textfield mainly) ****/
                    MuiFormLabel: {
                        root: {
                            position: 'static !important' as any,
                            transform: 'none !important',
                            color: `${colors.darkGrey100} !important`,
                            fontSize: '14px',
                            fontWeight: font.bold,
                            marginBottom: '5px',
                            lineHeight: '1.5',
                        },
                    },

                    /**** FORM HELPER TEXT ****/
                    MuiFormHelperText: {
                        root: {
                            fontSize: 14,
                            margin: '4px 12px 0 0 !important',
                            display: 'block !important',
                        },
                    },
                    /**** MUI FORM CONTROL LABEL (checkbox label mainly) ****/
                    MuiFormControlLabel: {
                        root: {
                            height: '40px',
                            marginLeft: 0,
                            // '&:focus-within': {
                            //     boxShadow: `${colors.primary} 0px 0px 2px 0px`,
                            // },
                        },
                        label: {
                            fontSize: '14px',
                            color: colors.textDefault,
                            fontWeight: font.semiBold,
                            paddingRight: '12px',
                            userSelect: 'text!important' as any,
                            display: 'inline-block',
                            lineHeight: 0,
                        },
                    },

                    /**** CHECKBOX   *****/
                    MuiCheckbox: {
                        root: {
                            width: '40px',
                            height: '40px',
                            backgroundColor: 'transparent',
                            '& input': {
                                width: '40px',
                                height: '40px',
                            },
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                    },

                    /***** MENU ******/
                    MuiMenu: {
                        paper: {
                            borderRadius: '2px !important',
                            borderImage: 'initial',
                            border: `2px solid ${colors.secondaryPurple100} !important`,
                            boxShadow: 'rgba(47, 54, 56, 0.2) 0px 16px 24px 0px !important',
                        },
                    },

                    /****** MENU ITEM *****/
                    MuiMenuItem: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: colors.primaryYellow40,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: colors.primaryYellow40,
                                },
                            },
                            '&:hover': {
                                backgroundColor: colors.primaryYellow20,
                            },
                            height: '40px',
                            minHeight: '40px',
                            borderWidth: '2px',
                            borderColor: 'transparent',
                            fontSize: '16px',
                            fontWeight: 400,
                            color: colors.darkGrey100,
                            '&.link-item': {
                                color: 'inherit',
                            },
                            '& .bw-simple-menu-item-icon': {
                                marginRight: '0px',
                            },
                            '& .bw-simple-menu-item-text': {
                                padding: '0',
                                '& span': {
                                    fontSize: '14px !important',
                                },
                            },
                        },
                        gutters: {
                            paddingLeft: '12px',
                            paddingRight: '12px',
                        },
                    },

                    MuiList: {
                        root: {
                            '&:focus': {
                                outline: 'none',
                            },
                        },
                    },

                    MuiListItemText: {
                        primary: {
                            fontSize: '16px',
                            fontWeight: 'normal',
                        },
                    },

                    /**** SNACK BAR *****/

                    MuiSnackbar: {
                        root: {
                            zIndex: 10200,
                        },
                    },
                    /*********    CARD   *********/
                    MuiCard: {
                        root: {
                            borderRadius: '0',
                            boxShadow: 'none',
                            backgroundColor: colors.lightGrey10,
                            '@media print': {
                                border: `1px solid ${colors.darkGrey60}`,
                                backgroundColor: 'none',
                            },
                        },
                    },
                    MuiCardHeader: {
                        root: {
                            paddingTop: '28px',
                            paddingLeft: '28px',
                            paddingRight: '28px',
                            paddingBottom: '22px',
                        },
                        content: {
                            overflow: 'hidden',
                        },
                        title: {
                            fontSize: '20px',
                            color: colors.darkGrey100,
                            fontWeight: font.bold,
                            lineHeight: '25px',
                            paddingBottom: '3px',
                        },
                        subheader: {
                            fontSize: '12px',
                            color: colors.textMeta,
                            lineHeight: '17px',
                        },
                        action: {
                            '& button': {
                                marginTop: '-3px',
                                marginRight: '-4px',
                            },
                        },
                    },
                    MuiCardContent: {
                        root: {
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingLeft: '28px',
                            paddingRight: '28px',
                            paddingBottom: '28px',
                            '&:last-child': {
                                paddingBottom: '15px',
                            },
                        },
                    },

                    /*********    DRAWER   *********/
                    MuiDrawer: {
                        root: {
                            display: 'flex',
                        },
                        paper: {
                            backgroundColor: colors.lightGrey60,
                            position: 'relative',
                        },
                        docked: {
                            height: '100%',
                        },
                        paperAnchorDockedLeft: {
                            borderLeft: 'none',
                            borderRight: 'none',
                            top: '0px',
                            left: '0px',
                            height: '100%',
                        },
                        paperAnchorDockedRight: {
                            borderLeft: 'none',
                            borderRight: 'none',
                            top: '0px',
                            right: '0px',
                            height: '100%',
                        },
                    },

                    /*********    DIALOG   *********/
                    MuiDialog: {
                        paper: {
                            width: '596px',
                            minHeight: '237px',
                            border: `2px solid ${colors.primaryBlue100}`,
                            borderRadius: 0,
                            boxShadow: 'none',
                        },
                    },
                    MuiDialogTitle: {
                        root: {
                            color: '#2f3638',
                            margin: '0',
                            padding: '33px 48px 18px 48px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            '&.MuiTypography-root': {
                                fontSize: '24px',
                                textOverflow: 'ellipsis',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    MuiDialogContent: {
                        root: {
                            padding: '0 48px',
                            margin: '0',
                            //All of this needs to go eventually
                            '& p': {
                                fontSize: '14px',
                                color: '#2f3638',
                                paddingLeft: '6px',
                                wordBreak: 'break-word',
                            },
                            '& .info': {
                                margin: '0 0 30px 0',
                                '& .category': {
                                    fontSize: '14px',
                                    fontWeight: font.semiBold,
                                    paddingBottom: '5px',
                                },
                                '& .value': {
                                    fontSize: '14px',
                                    fontWeight: '400',
                                },
                            },
                        },
                    },
                    MuiDialogActions: {
                        root: {
                            width: '100%',
                            height: '76px',
                            backgroundColor: colors.lightGrey20,
                            padding: '15px',
                            margin: '15px 0 0 0',
                            '& .secondary': {
                                color: colors.primaryBlue100,
                            },
                        },
                    },
                    MuiPaper: {
                        root: {
                            color: colors.textDefault,
                            '& .error-message': {
                                display: 'block',
                            },
                        },
                        rounded: {
                            borderRadius: '0px',
                        },
                        elevation8: {
                            boxShadow: 'none',
                            border: `2px solid ${colors.secondaryPurple100}`,
                        },
                    },

                    /*********    TOOLTIP   *********/
                    MuiTooltip: {
                        tooltip: {
                            backgroundColor: '#2F3638',
                            fontSize: '14px',
                            wordBreak: 'break-word',
                            position: 'relative',
                            top: '-5px',
                            minWidth: '50px',
                        },
                        popper: {
                            '&[data-popper-placement*="bottom"] span.arrow': {
                                position: 'absolute',
                                top: '0',
                                left: '25%',
                                right: '75%',
                                marginTop: '-0.95em',
                                fontSize: '6px',
                                width: '3em',
                                height: '1em',
                                '&::before': {
                                    content: '""',
                                    margin: 'auto',
                                    display: 'block',
                                    width: '0',
                                    height: '0',
                                    borderStyle: 'solid',
                                    borderWidth: '0 1em 1em 1em',
                                    borderColor: 'transparent transparent #2F3638 transparent',
                                },
                            },
                        },
                    },
                    /*********    List Items   *********/
                    MuiListItem: {
                        root: {
                            '&.Mui-selected': {
                                backgroundColor: colors.primaryYellow40,
                                fontWeight: 700,
                                '&:hover': {
                                    backgroundColor: colors.primaryYellow40,
                                },
                            },
                            '&:hover': {
                                backgroundColor: colors.primaryYellow20,
                            },
                        },
                        dense: {
                            paddingTop: '2px',
                            paddingBottom: '2px',
                        },
                        button: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            '&:hover': {
                                // saved search has bw bgHover color - is this for all list item buttons or just for saved search?
                                backgroundColor: 'rgba(63, 63, 63, 0.04)',
                            },
                        },
                    },

                    MuiListItemIcon: {
                        root: {
                            minWidth: '48px',
                        },
                    },

                    /*********    Chip   *********/
                    MuiChip: {
                        root: {
                            color: colors.primaryBlue100,
                            height: '32px',
                            padding: 0,
                            fontSize: '12px',
                            borderRadius: '50px',
                            backgroundColor: colors.secondaryOrange20,
                            '&.hlight': {
                                backgroundColor: colors.secondaryOrange40,
                            },
                        },
                        clickable: {
                            '&:hover': {
                                backgroundColor: colors.secondaryOrange40,
                            },
                            '&:focus': {
                                backgroundColor: colors.secondaryOrange40,
                                boxShadow: `0 0 0 3pt ${colors.secondaryOrange100}`,
                                outline: 'none',
                            },
                            '&:active': {
                                backgroundColor: colors.secondaryOrange60,
                            },
                        },
                        deletable: {
                            '&:hover, &:focus, &:active': {
                                backgroundColor: colors.secondaryOrange20,
                                outline: 'none',
                                boxShadow: 'none',
                            },
                        },
                        label: {
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            lineHeight: '20px',
                            fontSize: '16px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '180px',
                            fontWeight: 700,
                            display: 'inline-block',
                        },
                        icon: {
                            height: '16px',
                            width: '14px',
                            marginRight: '0',
                            marginLeft: '8px',
                            fill: colors.primaryBlue100,
                            stroke: colors.primaryBlue100,
                            flex: '0 0 auto',
                        },
                        deleteIcon: {
                            marginLeft: '0',
                            marginRight: '8px',
                            height: '14px',
                            width: '14px',
                            fill: colors.primaryBlue100,
                            stroke: colors.primaryBlue100,
                            flex: '0 0 auto',
                        },
                        sizeSmall: {
                            height: 22,
                        },
                        labelSmall: {
                            fontSize: '12px',
                            lineHeight: '22px',
                        },
                        iconSmall: {
                            height: 10,
                            width: 10,
                            marginLeft: 8,
                        },
                        deleteIconSmall: {
                            height: 12,
                            width: 12,
                            marginLeft: 0,
                            marginRight: 8,
                        },
                    },

                    /*********    SELECT   *********/
                    MuiSelect: {
                        root: {
                            color: colors.primaryBlue100,
                            backgroundColor: colors.primaryBlue20,
                            '& .app-icon.icon-chevron-down': {
                                position: 'absolute',
                                right: '7px',
                                top: 'calc(50% - 7px)',
                                pointerEvents: 'none',
                                fill: colors.primaryBlue100,
                            },
                        },
                    },
                    MuiDivider: {
                        root: {
                            backgroundColor: colors.primaryBlue40,
                        },
                    },
                    MuiPopover: {
                        paper: {
                            margin: '5px 0',
                        },
                    },

                    /*
                     * @material-ui/pickers
                     * https://material-ui-pickers.dev/guides/css-overrides
                     */
                    MuiPickersDay: {
                        day: {
                            backgroundColor: 'transparent',
                        },
                        daySelected: {
                            backgroundColor: colors.secondaryPurple100,
                        },
                        current: {
                            backgroundColor: colors.secondaryPurple20,
                        },
                    },

                    MuiRadio: {
                        root: {
                            padding: 0,
                            background: 'none',
                        },
                    },
                },
                this._getExtendedValue('overrides'),
            );
        },
        props: function () {
            return ThemeBuilder.nullMerge(
                {
                    MuiDialog: {
                        maxWidth: false,
                    },
                    MuiButtonBase: {
                        disableRipple: true,
                    },
                    MuiRadio: {
                        disableRipple: true,
                    },
                    MuiFormControlLabel: {
                        className: 'chbox-label',
                    },
                    MuiCheckbox: {
                        disableRipple: true,
                        icon: <BlankIcon />,
                        checkedIcon: <CheckedIcon />,
                        indeterminateIcon: <IndeterminateIcon />,
                    },
                    MuiFormHelperText: {
                        component: 'div',
                    } as any,
                    MuiMenu: {
                        TransitionComponent: Fade,
                        disableAutoFocusItem: true,
                        MenuListProps: {
                            disablePadding: true,
                        },
                    },
                    MuiPopover: {
                        TransitionComponent: Fade,
                    },
                    MuiPaper: {
                        square: true,
                    },
                    MuiChip: {
                        deleteIcon: <IconRouter name="close" />,
                    },
                },
                this._getExtendedValue('props'),
            );
        },
        palette: function (theme) {
            const colors = theme.colors as ThemeV1['colors'];

            return ThemeBuilder.nullMerge(
                {
                    primary: {
                        main: colors.primaryBlue80!,
                        dark: colors.primaryBlue100,
                        contrastText: '#FFF',
                    },
                    secondary: {
                        main: colors.primaryYellow80!,
                        dark: colors.primaryYellow100,
                        contrastText: '#FFF',
                    },
                    error: {
                        main: colors.primaryRed100!,
                    },
                    background: {
                        default: colors.lightGrey100,
                    },
                },
                this._getExtendedValue('palette'),
            );
        },
        typography: function ({font}) {
            return ThemeBuilder.nullMerge(
                {
                    fontSize: 14,
                    fontFamily: font.name,
                },
                this._getExtendedValue('typography'),
            );
        },
    },
    //Extends
    baseTheme,
);

export default theme;
