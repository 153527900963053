import makeStyles from '@mui/styles/makeStyles';

const MAX_WIDTH = '800px';

export default makeStyles((theme) => ({
    HSILoadingModal: {
        color: theme.colors.textDefault,
        fontSize: '16px',
        '& .MuiDialog-paper': {
            backgroundColor: '#D6E2EE',
            height: 'auto',
            maxWidth: MAX_WIDTH,
            flex: `1 1 ${MAX_WIDTH}`,
        },
        '& .loadingScreen': {
            backgroundColor: '#D6E2EE',
            '& .wrapper': {
                padding: '100px 0',
            },
            '& .loader-container': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .notification': {
                marginTop: '10px',
                fontWeight: 700,
                color: theme.textDefault,
                fontSize: '24px',
                textAlign: 'center',
            },
            '& .image': {
                display: 'block',
                margin: '0 auto',
                width: '352px',
                height: '352px',
            },
        },
    },
}));
