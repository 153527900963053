import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        lineHeight: 0,
        color: theme.colors.textDefault,
        width: 'var(--size)',
        height: 'calc(var(--size) * 0.75)',

        '& > div': {
            '--width': 'calc(0.7 * var(--size) / 3)',
            width: 'var(--width)',
            backgroundColor: theme.colors.textDefault,
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle',
            opacity: 0.75,
            borderRadius: '2px',
            animation: `1s ease 0s infinite normal none running $pulse-loader`,

            '&:nth-child(2)': {
                animationDelay: '100ms',
                margin: '0 calc(calc(var(--size) - var(--width) * 3) / 2)', //Math.floor((size - childStyle.width * 3) / 2);
            },

            '&:nth-child(3)': {
                animationDelay: '200ms',
            },
        },

        '&.lightGrey': {
            '& > div': {
                backgroundColor: theme.colors.lightGrey10,
            },
        },
    },

    '@keyframes pulse-loader': {
        '30%': {
            transform: 'scale(1)',
            opacity: 0.75,
        },
        '50%': {
            transform: 'scale(0.8)',
        },
        '70%': {
            transform: 'scale(1.2)',
            opacity: 1,
        },
        '90%': {
            transform: 'scale(1)',
            opacity: 0.75,
        },
    },
}));
