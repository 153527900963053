import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import compact from 'lodash/compact';
import find from 'lodash/find';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import IconRouter from 'hsi/components/IconRouter';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import NumericalMetric from 'hsi/components/aria/NumericMetric';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useBenchmarkSubtitle from 'hsi/hooks/useBenchmarkSubtitle';

//Actions
import {toggleConfig} from 'hsi/actions/resultsActions';

//Utils
import format from 'hsi/utils/number/format';
//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import useGetLoadData from '../useGetLoadData';

const type = 'totalVolume';

const TotalVolumeItem = ({data, metric, previousTitle, title, hasPreviousValue}) => (
    <div className="total">
        <dt className="title">{title}</dt>
        <dd className="results">
            {metric !== 'retweetRate' ? (
                <NumericalMetric className="number">{data.current}</NumericalMetric>
            ) : (
                <div className="number">{`${data.current}%`}</div>
            )}
            {hasPreviousValue && (
                <>
                    <div className="slash" />
                    <div className="comparison">
                        <div className="percentage">
                            {data.changePercentage > 0 && (
                                <IconRouter
                                    name="trend-up"
                                    aria-label={T('cards.totalVolume.trendUp')}
                                />
                            )}
                            {data.changePercentage < 0 && (
                                <IconRouter
                                    name="trend-down"
                                    aria-label={T('cards.totalVolume.trendDown')}
                                />
                            )}
                            <span>{data.shownPercentage}</span>
                        </div>
                        <div>
                            <span>{previousTitle}</span>
                            <span className="previousPercentage">
                                {metric !== 'retweetRate'
                                    ? format(data.previous, 'compact')
                                    : `${data.previous}%`}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </dd>
    </div>
);

//The components
const TotalVolume = React.forwardRef(({title, ...props}, ref) => {
    const classes = useStyles();
    const {
        links: {dashboardInfoTotalVolumeCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();

    //Redux
    const dispatch = useDispatch();
    const {data, error, loaded, loading} = useSelector((state) => state.chart[type]);
    //TODO: move this somewhere else
    const isSideDrawerOpen = useSelector((state) => state.cardPersistState).open;
    const metrics = useSelector((state) => state.cardPersistState[type]);
    const {startDate, endDate} = useSelector((state) => state.filters.dateRange);

    const {hasPreviousValue, benchmarkSubHeader} = useBenchmarkSubtitle(startDate, endDate);

    const types = useMemo(() => compact(Object.values(metrics)), [metrics]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(`cards.${type}.info.copy`)}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA],
    );

    const hasData = useMemo(() => !!data && types.length > 0, [data, types]);

    const loadData = useGetLoadData(type);

    const renderTotals = useMemo(() => {
        if (!hasData) {
            return null;
        }

        return (
            <dl className={classes.totalsContainer}>
                {types.map((metricType, i) => {
                    const metric = find(data, ({id}) => id === metricType?.id);

                    if (!metric) return null;

                    const {id, changePercentage, current, previous} = metric;

                    return (
                        <TotalVolumeItem
                            key={id + i}
                            data={{
                                current,
                                previous,
                                changePercentage,
                                shownPercentage:
                                    changePercentage === Infinity
                                        ? '∞'
                                        : `${Math.abs(changePercentage)}%`,
                            }}
                            hasPreviousValue={hasPreviousValue}
                            metric={id}
                            previousTitle={hasPreviousValue ? T(`cards.${type}.previous`) : null}
                            title={T(`cards.${type}.sections.${id}`)}
                        />
                    );
                })}
            </dl>
        );
    }, [classes, data, hasData, types, hasPreviousValue]);

    //Callbacks
    const onConfigClick = useCallback(() => {
        trackWithSearchData('configDrawerToggled', {
            state: !isSideDrawerOpen ? 'open' : 'closed',
            type,
        });
        dispatch(toggleConfig('totalVolume'));
    }, [dispatch, isSideDrawerOpen, trackWithSearchData]);

    //Render the component
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle
                    hasConfig
                    onConfigClick={onConfigClick}
                    title={title}
                    tooltipComponent={popup}
                    type={type}
                    hasData
                />
            }
            subTitle={hasPreviousValue ? benchmarkSubHeader : T(`cards.${type}.noBenchmark`)}
            error={error}
            hasData={hasData}
            loaded={loaded}
            loading={loading}
            loadData={loadData}
            ref={ref}
            data-testid={type}
            type={type}
        >
            {renderTotals}
        </CardLoadState>
    );
});

TotalVolume.displayName = 'TotalVolume';

TotalVolume.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
    }),
};

export default TotalVolume;
