import {useMemo} from 'react';
import {useSelector} from 'react-redux';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {
    AutosuggestTypes,
    AutoSuggestSettings,
    AutosuggestDataTypeFromAutosuggestType,
} from 'hsi/types/autosuggest';

//Constants
const noop = () => null;
const ARRAY: any[] = [];

//The hook
export default function useGetAutosuggestSettings<TAutosuggestType extends AutosuggestTypes>(
    autosuggestType: TAutosuggestType,
    projectId: number,
): AutoSuggestSettings<AutosuggestDataTypeFromAutosuggestType<TAutosuggestType>> {
    const {autosuggest} = useConfig();

    const autosuggestConfig = autosuggest[autosuggestType];

    const valuesSelector = useMemo(
        () =>
            autosuggestConfig?.valuesSelector
                ? (state: RootReducer) => autosuggestConfig?.valuesSelector(state, projectId)
                : noop,
        [autosuggestConfig, projectId],
    );

    type isLoadingSelectorType = (state: RootReducer) => boolean | null;

    const isLoadingSelector: isLoadingSelectorType = useMemo<isLoadingSelectorType>(
        () =>
            autosuggestConfig?.isLoadingSelector
                ? (state: RootReducer) => autosuggestConfig?.isLoadingSelector(state, projectId)
                : (noop as isLoadingSelectorType),
        [autosuggestConfig, projectId],
    );

    //Load autosuggest options if required
    const autosuggestOptions = useSelector(valuesSelector);
    const isLoading: boolean = !!useSelector(isLoadingSelector);

    return useMemo(() => {
        return {
            loadDataHandler: (prefix?: string) =>
                autosuggestConfig.loadDataHandler(projectId, prefix),
            justOnce: autosuggestConfig.justOnce,
            listableKey: autosuggestConfig.listableKey,
            idKey: autosuggestConfig.idKey || autosuggestConfig.listableKey,
            loading: isLoading,
            results: autosuggestOptions || ARRAY,
        };
    }, [projectId, autosuggestConfig, autosuggestOptions, isLoading]);
}
