import React from 'react';
import IconRouter from 'hsi/components/IconRouter';

import useStyles from './styles';

export function BlankIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame blank" />
        </div>
    );
}

export function CheckedIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame checked">
                <svg
                    className="chbox-icon"
                    width="10"
                    height="10"
                    viewBox="0 0 20 20"
                    fill="#2f3638"
                    aria-hidden="true"
                >
                    <polygon points="7 12 3 8 0 11 7 18 20 5 17 2" />
                </svg>
            </div>
        </div>
    );
}

export function IndeterminateIcon() {
    useStyles();

    return (
        <div className="chbox-icon-wrapper">
            <div className="chbox-icon-frame icon-frame-indeterminate blank">
                <IconRouter
                    aria-hidden="true"
                    name="d-delete"
                    className="indeterminate-chbox-icon"
                />
            </div>
        </div>
    );
}
