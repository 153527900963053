import { forwardRef, Ref } from 'react';
import useStyles from './styles';
import { getArcProperties } from './utils';

interface ProgressInfiniteProps {
    className?: string;
    size?: 'tiny' | 'small' | 'medium' | 'large';
    sizeRem?: string;
}

const ProgressInfinite = forwardRef<SVGSVGElement, ProgressInfiniteProps>(
    ({className, size}, ref) => {
        const {diameter, innerRadius, strokeWidth, strokeDasharray, strokeDashoffset} =
            getArcProperties({percent: 75});

        const {classes, cx} = useStyles();

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className={cx(
                    className,
                    classes['ax-radial-progress'],
                    classes[`ax-radial-progress--${size || 'medium'}`],
                )}
                ref={ref as Ref<SVGSVGElement>}
            >
                <g transform={`translate(${diameter / 2}, ${diameter / 2}) rotate(-90)`}>
                    <linearGradient id="ax-progress-infinite__filter--subtle">
                        <stop
                            className="ax-progress-infinite__arc-gradient-stop--subtle"
                            offset="0%"
                        ></stop>
                        <stop
                            className="ax-progress-infinite__arc-gradient-stop--subtle"
                            offset="50%"
                        ></stop>
                        <stop
                            className="ax-progress-infinite__arc-gradient-stop--subtle"
                            offset="90%"
                        ></stop>
                    </linearGradient>
                    <circle
                        className="ax-progress-infinite__arc"
                        r={innerRadius}
                        style={{
                            stroke: `url(#ax-progress-infinite__filter--subtle)`,
                            strokeDasharray,
                            strokeDashoffset,
                            strokeWidth,
                        }}
                    />
                </g>
            </svg>
        );
    },
);

export default ProgressInfinite;
