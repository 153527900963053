// TODO: improve types as I make more reducers TS
// Actually TODO I don't think this approach is fundamentally very good. Most of these should be replaced with hooks
// We defo should not be adding more to this file.
import {RootState} from 'hsi/utils/configureStore';

export const selectAdditionalQueries = (state: RootState) => state.query?.additionalQueries;

export const selectCanImpersonate = (state: RootState): boolean => state.user.canImpersonate;

export const selectDrillInCard = (state: RootState) => state.mentions.drillInCard;

export const selectImpersonationLoading = (state: RootState) => state.user.impersonationLoading;

export const selectIsImpersonated = (state: RootState) => state.user.isImpersonated;

export const selectIsViewOnly = (state: RootState) => state.user.isViewOnly as boolean;

export const selectLinkedinChannelIds = (state: RootState) => state?.query?.linkedinChannelIds;

export const selectNumFilters = (state: RootState) => state.filters.numAppliedFilters;

export const selectProjectId = (state: RootState) => state.query?.context?.projectId;

export const selectProjects = (state: RootState) => state.user?.projects;

export const selectSavedSearch = (state: RootState): any | undefined =>
    state.query?.context?.savedSearch;

export const selectSavedSearchId = (state: RootState) => state.query?.context?.savedSearchId;

export const selectSearches = (state: RootState) => (state.search as any)?.searches;

export const selectSessionStart = (state: RootState) => state.user.sessionStart;

export const selectSavingQuery = (state: RootState) => state?.query?.saving;

export const selectIsEditSearch = (state: RootState) => state?.query?.context?.isEditSearch;

export const selectIsMultipleSearch = (state: RootState) => state?.query?.context?.isMultipleSearch;

export const selectSearchType = (state: RootState) => state.query?.context?.searchType;

export const selectIsGuidedSearch = (state: RootState) => state.query?.isGuided;
