import pickBy from 'lodash/pickBy';
import qs from 'qs';
import {getSearchType} from 'hsi/utils';
import {APIFilterFormat, DateRange} from 'hsi/types/filters';
import {LinkedinChannelIdsType} from 'hsi/types/shared';
import {QueryContextType} from 'hsi/types/query';

/**
 * Converts URL params object into a properly formated query string
 * @param state the values to convert
 * @returns a valid URL query string
 */
export const serializeSearch = (state: string | {[key: string]: string}) =>
    new URLSearchParams(state).toString();

/**
 * Convert URL query string into {[key]: value}
 *
 * @param state the URL query string
 * @returns parsed URL query string values
 */
export const deserializeSearch = (state: string) => qs.parse(state, {ignoreQueryPrefix: true});

// {

//     const result = fromPairs([...new URLSearchParams(state).entries()]);
// console.log(state, result, qs.parse(state, {ignoreQueryPrefix: true}));
//     return result;
// }

//keeps properties that are truthy AND not empty arrays
export const cleanObject = <T extends {}>(object: T): Partial<T> =>
    pickBy(object, (value) => !!value && (!Array.isArray(value) || !!value.length)) as Partial<T>;

interface MatchType {
    params: {
        id: string;
        sortBy: string;
        sortOrder: string;
    };
}

export const getUrlParams = (location: Location, match: MatchType, params: object) => {
    if (params) {
        return params;
    } else {
        return {
            search: deserializeSearch(location.search),
            searchType: getSearchType(match?.params?.id),
            searchId: match.params.id,
            sortBy: match.params.sortBy,
            sortOrder: match.params.sortOrder,
        };
    }
};

export const getFilteredQueryIds = (queryIds: number[], filterQueryId: string | null): number[] => {
    const filteredQueryIds = queryIds.filter((id) =>
        !!filterQueryId ? id.toString() === filterQueryId : true,
    );

    return filteredQueryIds;
};


export function getSearchParams(
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries?: number[],
    linkedinChannelIds?: LinkedinChannelIdsType,
    asObject?: false
): string;

export function getSearchParams(
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries?: number[],
    linkedinChannelIds?: LinkedinChannelIdsType,
    asObject?: true
): SavedSearchParamsType | QuickSearchParamsType;

export function getSearchParams(
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries?: number[],
    linkedinChannelIds?: LinkedinChannelIdsType,
    asObject: boolean = false
) {
    if (queryContext.isSavedSearch && !queryContext.isEditSearch) {
        if (!additionalQueries || !linkedinChannelIds) {
            throw new Error(
                'Invalid arguments, additionalQueries array and linkedinChannelIds array are required for saved searches (except for edit mode)',
            );
        }

        return getSavedSearchParams(
            filterParams,
            dateRange,
            queryContext,
            additionalQueries ?? [],
            linkedinChannelIds ?? [],
            asObject!!,
        );
    }

    return getQuickSearchParams(filterParams, dateRange, queryContext, asObject!!);
}

export type SavedSearchParamsType = Omit<APIFilterFormat, 'queryAppend' | 'queryId'> & {
    queryId: number[];
    startDate: string;
    endDate: string;
    timezone: string;
    limit?: number;
    orderBy?: string;
    metrics?: string; 
    extract?: string;
};

function getSavedSearchParams (
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries: number[],
    linkedinChannelIds: LinkedinChannelIdsType,
    asObject: true
): SavedSearchParamsType

function getSavedSearchParams (
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries: number[],
    linkedinChannelIds: LinkedinChannelIdsType,
    asObject: false
): string

function getSavedSearchParams (
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries: number[],
    linkedinChannelIds: LinkedinChannelIdsType,
    asObject: boolean
): string | SavedSearchParamsType

function getSavedSearchParams (
    filterParams: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    additionalQueries: number[],
    linkedinChannelIds: LinkedinChannelIdsType,
    asObject: boolean
) {
    const queryId = getFilteredQueryIds(
        [
            queryContext.savedSearchId,
            ...(additionalQueries || []),
            ...(linkedinChannelIds || []),
        ],
        filterParams.queryId?.toString() ?? null,
    );

    const paramsObj: SavedSearchParamsType = {
        queryId: queryId as any,//not sure why this is required
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        ...filterParams,
        timezone: dateRange?.timezone || queryContext?.project?.timezone,
    };

    return asObject 
        ? paramsObj 
        : queryParamsToString(paramsObj);
};

type QuickSearchParamsType = {
    search: string,
    startDate: string,
    endDate: string,
    timezone: string,
} & Omit<APIFilterFormat, 'queryAppend' | 'queryId'>;

function getQuickSearchParams (
    filters: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    asObject: false,
): string

function getQuickSearchParams (
    filters: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    asObject: true,
) : QuickSearchParamsType

function getQuickSearchParams (
    filters: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    asObject: boolean,
): string | QuickSearchParamsType

function getQuickSearchParams (
    {queryAppend, ...filterParams}: APIFilterFormat,
    dateRange: DateRange,
    queryContext: QueryContextType,
    asObject: boolean = false,
) {
    const paramsObj = {
        search: queryAppend
            ? `(${queryContext.urlSearchParams.query})${queryAppend}`
            : queryContext.urlSearchParams.query,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        ...filterParams,
        timezone: dateRange?.timezone || queryContext?.project?.timezone,
    };

    return asObject
        ? paramsObj 
        : queryParamsToString(paramsObj);
};

export const isProduction = () =>
    window?.location?.hostname &&
    window.location.hostname.includes('brandwatch.com') &&
    !window.location.hostname.includes('stage.brandwatch.com');

export const isStagingOrPR = () =>
    window?.location?.href &&
    (window.location.href.includes('stage.brandwatch.com') ||
        window.location.href.includes('pr.analytics-stage.gcp0.bwcom.net'));

export function stripTrailingSlash(str: string) {
    return str.endsWith('/') ? str.slice(0, -1) : str;
}

export const getSearchResultsUrl = (item: any) =>
    `/search/results/${item?.project?.id || item.projectId}/${item.id}`;

export function queryParamsToString(paramsObj: any) {
    return qs.stringify(
        paramsObj,
        {addQueryPrefix: true, arrayFormat: 'brackets', indices: false},
    );
}