/**
 * Stream Service
 */
import http from '../classes/Http';

let locCtrl: AbortController | undefined;

class AutocompleteService {
    static async loadLocations(prefix?: string) {
        if (locCtrl) locCtrl.abort();
        locCtrl = new AbortController();
        const {signal} = locCtrl;

        const params = new URLSearchParams({
            pageSize: '10',
            page: '0',
            ...(prefix && {prefix}),
        });

        try {
            return (
                await http.get(`/api/locations?${params.toString()}`, {
                    signal,
                }).then((res) => res.body)
            );
        } finally {
            locCtrl = undefined;
        }
    }

    static async loadLanguages() {
        return http.get('/api/languages').then((res) => res.body);
    }

    static async loadCategories(projectId: number) {
        return http.get('/api/projects/' + projectId + '/categories').then((res) => res.body);
    }

    static async loadWorkflow(projectId: number) {
        return http.get('/api/projects/' + projectId + '/workflow').then((res) => res.body);
    }
}

export default AutocompleteService;
