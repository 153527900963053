import React, {useMemo, forwardRef} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

//Components

//Hooks

//Other
import useStyles from './styles';

//Consts
const NAMED_SIZES = {small: 18, medium: 26, large: 40};

//The component
const HSILoader = forwardRef(
    ({className, size, style, 'data-testid': testId = 'pulse-loader'}, ref) => {
        const classes = useStyles();

        if (typeof size == 'string') {
            size = NAMED_SIZES[size] + 'px';
        }

        return useMemo(
            () => (
                <div
                    className={cn(classes.root, className)}
                    data-testid={testId}
                    style={{...style, '--size': size}}
                    ref={ref}
                >
                    <div />
                    <div />
                    <div />
                </div>
            ),
            [classes.root, className, testId, style, size, ref],
        );
    },
);

HSILoader.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['small', 'medium', 'large'])]),
    style: PropTypes.object,
};

HSILoader.defaultProps = {
    size: 'medium',
};

export default HSILoader;
