import baseConfig from 'hsi/configurations/_base/config';
import iconsConfig from 'hsi/icons/config';
import {AppConfigType} from 'hsi/types/config';

//Utils
import {isStagingOrPR} from 'hsi/utils/url';

//-Links
const basePath = isStagingOrPR() ? 'https://staging.hootsuite.com' : 'https://hootsuite.com';
const externalReport = basePath + '/dashboard#/analytics/new-report/11';

const links = {
    ...baseConfig.links,
    externalReport,
    searchHelp: 'https://help.hootsuite.com/hc/en-us/articles/360035546313',
    helpCenter:
        'https://help.hootsuite.com/hc/en-us/categories/1260800890090-Discover-trends-with-Insights',
    feedbackLink: 'https://forms.gle/zBhNUCSbpzXawevcA',
    alertsLearnMore:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306389-Create-email-alerts-for-your-saved-searches',
    alertInviteAction: 'https://hootsuite.com/',

    //card info popup ctas
    dashboardInfoTotalVolumeCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Total-Vol',
    dashboardInfoMentionsHistoryCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Mention-Vol-Over-Time',
    dashboardInfoHeatmapCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Mention-Vol-By-Day',
    dashboardInfoWordCloudCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Word-Cloud',
    dashboardInfoBenchmarkCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Mention-Vol-Benchmark',
    dashboardInfoSentimentVolumeCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Sentiment-Vol',
    dashboardInfoSentimentHistoryCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Sentiment-Vol-Over-Time',
    dashboardInfoEmotionVolumeCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Emotion-Vol',
    dashboardInfoEmotionHistoryCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Emotion-Vol-Over-Time',
    dashboardInfoTopSharedURLsCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Shared-URLs',
    dashboardInfoTopSitesCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Sites',
    dashboardInfoTopAuthorsCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Authors',
    dashboardInfoTopHashtagsCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Hashtags',
    dashboardInfoTopInterestsCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Interests',
    dashboardInfoTopLanguagesCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Top-Languages',
    dashboardInfoGenderCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Gender-Split',
    dashboardInfoGeographyCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Vol-By-Countries',
    dashboardInfoTopicWheelCTA:
        'https://help.hootsuite.com/hc/en-us/articles/1260804306429-Metrics-in-Insights#Topic-Wheel',
    //dashboardInfoMentionsCTA: null,
    dataManagementLearnMore: 'https://help.hootsuite.com/hc/articles/1260804250230',
    //dashboardInfoNetSentimentCTA: undefined,
} as const;

const config: AppConfigType = {
    ...baseConfig,
    appName: 'Insights',
    appSource: 'HSI',

    //Path to SVG file generated by SvgStore plugin
    svgPath: iconsConfig.hsi.fileName,
    toggle: {
        ...baseConfig.toggle,
        defaultMode: 'switch',
    },
    links,
    searchResults: {
        ...baseConfig.searchResults,
        cardHeights: {
            totalMentions: 157,
            totalVolume: 309,
            //totalVolumeBySearch: null,
            mentionsHistory: 467,
            heatmap: 500,
            wordCloud: 368,
            benchmark: 735,
            sentimentVolume: 409,
            sentimentVolumeBySearch: 380,
            sentimentHistory: 467,
            netSentimentHistory: 425,
            emotionVolume: 429,
            emotionHistory: 467,
            pageTypeBySearch: 450,
            topSharedURLs: 618,
            topsites: 618,
            topauthors: 618,
            tophashtags: 618,
            topinterests: 533,
            toplanguages: 533,
            gender: 370,
            geography: 816,
            topicWheel: 453,
        },
        cardSectionsOrder: [
            'mentions',
            'benchmark',
            'sentiment',
            'emotion',
            'wordCloud',
            'topMeasures',
            'demographics',
            'topicWheel',
        ],
    },
    filters: {
        ...baseConfig.filters,
    },
    themeColors: {
        ...baseConfig.themeColors,
        colorScaleDefault: '#f0f2f2',
        primaryColor: '#143059',
        topicType: {
            hashtag: '#4CC1D4',
            place: '#895CA2',
            organisation: '#FD8126',
            person: '#D3CD0B',
            word: '#393A3A',
            phrase: '#8C4019',
        },
        gender: {male: '#543D80', female: '#C85960', unknown: '#878787'},
        heatmap: ['#D0D6DE', '#A1ACBD', '#72839B', '#143059'],
        sentiment: {positive: '#3E9348', negative: '#CB333A', neutral: '#ADAAB3'},
        volume: {low: '#C2C2C2', mid: '#5ACBDC', high: '#3B74B7'},
        trending: {low: '#C2C2C2', mid: '#F9B800', high: '#FF634A'},
        random: [
            '#f598b7',
            '#e35a7a',
            '#906ba7',
            '#90a015',
            '#55cee6',
            '#24adbd',
            '#c8c819',
            '#8ac539',
            '#ffbe0a',
            '#f99232',
            '#9e6139',
            '#78909c',
        ],
        none: ['#393A3A'],
        default: ['#143059', '#72839B', '#43597A', '#D0D6DE', '#A1ACBD'],
        emotion: {
            anger: '#d78388',
            disgust: '#756499',
            fear: '#ff7600',
            joy: '#fcbb48',
            sadness: '#72839b',
            surprise: '#539e65',
        },
        pageTypes: {
            twitter: '#69ABE9',
            facebook: '#1877F2',
            facebook_public: '#1B50F2',
            instagram: '#723D9E',
            tumblr: '#39455C',
            forum: '#5BA193',
            youtube: '#CD321F',
            reddit: '#E24F27',
            news: '#892E1B',
            blog: '#F2C644',
            review: '#ACCC73',
            qq: '#4A8DB5',
            linkedin: '#E35A7A',
        },
        sparkline: '#0D2F5C',
        success: '#8dc63f',
        error: '#e23145',
        warning: '#f9b800',
        choroplethMapColors: {
            render: ['#D0D6DE', '#A1ACBD', '#72839B', '#143059'],
            hover: '#F99132',
            pressed: '#F99132',
        },
        heatmapColors: ['#D0D6DE', '#A1ACBD', '#72839B', '#143059'],
    },
    exportType: 'hootsuite',
    editorConfig: {
        padding: {
            top: 10,
            bottom: 10,
        },
        fontSize: 15,
        lineHeight: 22,
        suggestFontSize: 16,
        suggestLineHeight: 20,
        fontFamily: 'Monaco, Menlo, monospace',
    },
    lineChart: {
        leftPadding: -15,
        hasSideLabel: false,
        tickLine: true,
        axisLine: true,
        tickMargin: 15,
    },
    cardExportMenu: {
        hasFormatIcon: true,
    },
    createReport: {
        hasExternalReport: true,
    },
    customSnackbars: {
        exportResultsToPDF: 'HSILoadingScreen',
        exportCard: 'HSILoadingScreen',
        exportMentionsToPDF: 'HSILoadingScreen',
        exportMentionsToCSV: 'HSILoadingScreen',
    },
    datePicker: {
        hasTimezoneIcon: true,
    },
    //XXX linkedin disabled for HSI: pageTypes, sourceTypes
    _basePageTypes: baseConfig._basePageTypes,
    targettedData: baseConfig.targettedData,
    hasIcons4layoutSelection: true,
} as const;

export default config;
