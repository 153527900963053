import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        padding: '24px',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        color: theme.colors.textDefault,
        paddingTop: '35vh',
    },
    icon: {
        width: '49px',
        height: '49px',
        marginBottom: '45px',
        fill: theme.colors.textDefault,
    },
    title: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: '16px',
        marginTop: '11px',
    },
    link: {
        ...theme.mixin.linkButton,
        fontWeight: 'bold',
    },
}));
